<template>
    <custom-table
        :title="$t('pages.ecommerce.order.creator.title')"
        :subTitle="$t('pages.ecommerce.order.creator.subTitle')"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        @action="handleClickAction"
        @changeTable="handleTableChange">
        <template v-slot:state="{ row: record }">
            <span :class="status[record.status_id].badge" class="badge">{{ $t('pages.ecommerce.order.creator.status.' + status[record.status_id].code) }}</span>
        </template>
        <template v-slot:completedAt="{ row: record }">
            {{ record.completed_at ? $moment(record.completed_at).format("DD.MM.YYYY - HH:mm:ss") : "-" }}
        </template>
        <template v-slot:createdAt="{ row: record }">
            {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a v-on:click="detailRecord(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/general/gen004.svg" />
                    </span>
                </a>
                <a v-on:click="exportRecord(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm" v-if="record.status_id == 3">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/files/fil021.svg"/>
                    </span>
                </a>
            </div>
        </template>
    </custom-table>

    <div class="modal fade" id="kt_modal_export_filter" ref="exportFilterModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_export_filter_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_export_filter_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onExportFilter()" :model="form.export.filter" ref="exportFilterForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_export_filter_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-10 p-6">
                                <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                    <inline-svg src="/media/icons/duotune/general/gen044.svg" />
                                </span>
                                <div class="d-flex flex-stack flex-grow-1">
                                    <div class="fw-bold">
                                        <div class="fs-6 text-gray-600">{{ $t('pages.ecommerce.order.creator.messages.export') }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('common.status') }}</label>
                                <el-form-item prop="status_ids">
                                    <el-select v-model="form.export.filter.status_ids" class="w-100" :placeholder="$t('common.chooseSelect')" clearable multiple>
                                        <el-option v-for="(state, stateIndex) in exportStatus" :key="stateIndex" :value="(stateIndex + 1)" :label="$t('pages.ecommerce.order.creator.exportStatus.' + state)"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("common.export") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_detail" ref="detailModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_reservation_detail_header">
                    <h2 class="fw-bolder">{{ $t('pages.ecommerce.order.creator.detail.title') }}</h2>
                    <div id="kt_modal_reservation_detail_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <div class="modal-body py-10 px-lg-17" v-if="form.detail.payload && form.detail.payload.items && form.detail.payload.items.length">
                    <div class="scroll-y me-n7 pe-7" id="kt_modal_reservation_detail_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                        <div v-for="(item, itemIndex) in form.detail.payload.items" :key="itemIndex">
                            <div class="mt-7">
                                <h2 class="mb-8">{{ sprintf($t('pages.ecommerce.order.creator.detail.productTitlePattern'), [form.detail.products[item.id] ? form.detail.products[item.id].translate.title : "-"]) }}</h2>
                                <div class="fv-row mb-3">
                                    <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.order.creator.detail.cols.quantity') }}:</label>
                                    <span class="text-gray-500 fw-bold fs-6">{{ item.quantity }}</span>
                                </div>
                                <div class="fv-row mb-3" v-if="typeof(item.usage_min_date) != 'undefined'">
                                    <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.order.creator.detail.cols.usageMinDate') }}:</label>
                                    <span class="text-gray-500 fw-bold fs-6">{{ item.usage_min_date ? $moment(item.usage_min_date).format("DD.MM.YYYY - HH:mm:ss") : $t('common.indefinite')}}</span>
                                </div>
                                <div class="fv-row mb-3" v-if="typeof(item.usage_max_date) != 'undefined'">
                                    <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.order.creator.detail.cols.usageMaxDate') }}:</label>
                                    <span class="text-gray-500 fw-bold fs-6">{{ item.usage_max_date ? $moment(item.usage_max_date).format("DD.MM.YYYY - HH:mm:ss") : $t('common.indefinite')}}</span>
                                </div>
                                <div v-if="item.addons && item.addons.length">
                                    <el-divider content-position="center" class="mb-10">{{ $t('pages.ecommerce.order.creator.detail.addons') }}</el-divider>
                                    <div v-for="(addon, addonIndex) in item.addons" :key="addonIndex" :class="addonIndex != 0 && 'mt-7'">
                                        <h4 class="mb-5">{{ sprintf($t('pages.ecommerce.order.creator.detail.addonTitlePattern'),[(addonIndex + 1)]) }}</h4>
                                        <div class="ps-5">
                                            <div class="fv-row" v-if="addon.title">
                                                <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.order.creator.detail.cols.title') }}:</label>
                                                <span class="text-gray-500 fw-bold fs-6">{{ addon.title }}</span>
                                            </div>
                                            <div class="fv-row">
                                                <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.order.creator.detail.cols.quantity') }}:</label>
                                                <span class="text-gray-500 fw-bold fs-6">{{ addon.quantity }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="item.package_items && item.package_items.length">
                                    <el-divider content-position="center" class="mb-10">{{ $t('pages.ecommerce.order.creator.detail.packageItems') }}</el-divider>
                                    <div v-for="(packageItem, packageItemIndex) in item.package_items" :key="packageItemIndex" :class="packageItemIndex != 0 && 'mt-7'">
                                        <h4 class="mb-5">{{ sprintf($t('pages.ecommerce.order.creator.detail.packageItemTitlePattern'),[(packageItemIndex + 1)]) }}</h4>
                                        <div class="ps-5">
                                            <div class="fv-row mb-1" v-if="packageItem.title">
                                                <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.order.creator.detail.cols.title') }}:</label>
                                                <span class="text-gray-500 fw-bold fs-6">{{ packageItem.title }}</span>
                                            </div>
                                            <div class="fv-row mb-1" v-if="typeof(packageItem.custom_usage_min_date) != 'undefined'">
                                                <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.order.creator.detail.cols.usageMinDate') }}:</label>
                                                <span class="text-gray-500 fw-bold fs-6">{{ packageItem.custom_usage_min_date ? $moment(packageItem.custom_usage_min_date).format("DD.MM.YYYY - HH:mm:ss") : $t('common.indefinite')}}</span>
                                            </div>
                                            <div class="fv-row mb-1" v-if="typeof(packageItem.custom_usage_max_date) != 'undefined'">
                                                <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.order.creator.detail.cols.usageMaxDate') }}:</label>
                                                <span class="text-gray-500 fw-bold fs-6">{{ packageItem.custom_usage_max_date ? $moment(packageItem.custom_usage_max_date).format("DD.MM.YYYY - HH:mm:ss") : $t('common.indefinite')}}</span>
                                            </div>
                                            <div v-for="(addon, addonIndex) in packageItem.addons" :key="addonIndex" :class="addonIndex != 0 && 'mt-5'">
                                                <h6 class="mb-4">{{ sprintf($t('pages.ecommerce.order.creator.detail.addonTitlePattern'),[(addonIndex + 1)]) }}</h6>
                                                <div class="ps-5">
                                                    <div class="fv-row" v-if="addon.title">
                                                        <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.order.creator.detail.cols.title') }}:</label>
                                                        <span class="text-gray-500 fw-bold fs-6">{{ addon.title }}</span>
                                                    </div>
                                                    <div class="fv-row">
                                                        <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.order.creator.detail.cols.quantity') }}:</label>
                                                        <span class="text-gray-500 fw-bold fs-6">{{ addon.quantity }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer flex-center">
                    <button class="btn btn-lg btn-primary" type="button" v-on:click="hideModal($refs.detailModal)">{{ $t("btn.close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";
export default {
    name: "index",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.ecommerce.order.creator.cols.creator"),
                    key: "admin.full_name"
                },
                {
                    name: this.$t("pages.ecommerce.order.creator.cols.totalUser"),
                    key: "total"
                },
                {
                    name: this.$t("pages.ecommerce.order.creator.cols.productCount"),
                    key: "product_count"
                },
                {
                    name: this.$t("pages.ecommerce.order.creator.cols.success"),
                    key: "success"
                },
                {
                    name: this.$t('common.status'),
                    scopedSlots: {customRender: "state"}
                },
                {
                    name: this.$t("pages.ecommerce.order.creator.cols.completedAt"),
                    scopedSlots: {customRender: "completedAt"}
                },
                {
                    name: this.$t("common.createdAt"),
                    scopedSlots: {customRender: "createdAt"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                detail: {},
                export: {
                    filter: {}
                },
                data: {}
            },
            status: {
                1: {
                    code: 'pending',
                    badge: 'badge-primary'
                },
                2: {
                    code: 'processing',
                    badge: 'badge-info'
                },
                3: {
                    code: 'completed',
                    badge: 'badge-success'
                }
            },
            exportStatus: ['pending', 'created', 'already_exist', 'error'],
        }
    },
    computed: {
        table() {
            return this.$store.state.ecommerce.order.creator.table;
        },
    },
    mounted() {
        this.setCurrentPageBreadcrumbs(this.$t("menu.orderCreator"), [this.$t("menu.ecommerceManagement"), this.$t("menu.orderManagement")]);
        this.$store.dispatch('ecommerce/order/creator/get', {
            page: {},
            filterData: {
                sort: 'id:desc',
            }
        });
    },
    methods: {
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.$router.push({
                        path: "/ecommerce/order-management/order/creator/save"
                    });
                    break;

                default:
                    break;
            }
        },
        refreshTable() {
            this.$store.dispatch("ecommerce/order/creator/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("ecommerce/order/creator/get", {
                page: pagination,
                filterData: Object.assign({
                    sort: 'id:desc',
                }, filterData)
            });
        },
        detailRecord(record) {
            this.form.detail.payload = record.payload;
            this.form.detail.products = {};

            let productIDs = record.payload.items.map((item) => {
                return item.id;
            });

            this.axios.get(this.endpoints['ecommerce_product'], {
                params: {
                    ids: productIDs
                }
            }).then(response => {
                let data = response.data.data;

                data.forEach((product) => {
                    product.translate = this.resolveDatum(product.translations, this.$root.defaultLanguage.id, 'language_id');
                    this.form.detail.products[product.id] = product;
                });

                this.showModal(this.$refs.detailModal);
            });
        },
        exportRecord(record){
            this.form.updateStatus = false;
            this.form.export = {
                id: record.id,
                filter: {}
            };
            this.form.title = this.$t("pages.ecommerce.order.creator.exportFilter");
            this.showModal(this.$refs.exportFilterModal);
        },
        onExportFilter() {
            this.form.loading = true;
            this.axios({
                url: this.sprintf('%s/%d', [this.endpoints['ecommerce_order_creator_export'], this.form.export.id]),
                method: 'get',
                responseType: "blob",
                params: this.form.export.filter
            }).then(response => {
                const type = response.headers['content-type']
                const blob = new Blob([response.data], {type: type, encoding: 'UTF-8'})
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.click();

                this.hideModal(this.$refs.exportFilterModal);
            }).finally(() => {
                this.form.loading = false;
            });
        }
    }
}
</script>

<style></style>